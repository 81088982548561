import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell acampanat que posteriorment s’obri i fa de 4 a 10 cm de diàmetre de color gris cendra a plom, més clar cap al marge, brillant en temps sec i un poc viscós en temps humit. Pot estar parcialment cobert per trossos de vel universal, que són grossos, irregulars i blans. Les làmines són blanques, juntes, molt amples i lliures i el peu també blanc amb tons grisencs de 6-12 x 0,5-1 cm que s’enfunda en una volva blanca que envolta la base del peu. Les espores són blanques en massa, esfèriques, de 9-12 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      